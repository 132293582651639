<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.value" clearable placeholder="根据名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="begDate" label="发布日期" width="200" :formatter="v=>{return v.begDate?new Date(v.begDate).format('yyyy/MM/dd'):''}" />
      <el-table-column prop="title" label="标题" min-width="200" />
      <el-table-column prop="status" label="状态" min-width="200">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.status =='publish'">已发布</el-tag>
          <el-tag type="success" v-if="scope.row.status =='edit'">编辑中</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createAt" label="创建时间" width="200" :formatter="v=>{return v.createAt?new Date(v.createAt).format('yyyy/MM/dd'):''}" />
      <el-table-column width="180" align="center" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="publish(scope.row.id)">发布</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { del, publi } from "@/api/annunciate";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      downloadLoading: false,
      loading: false,
      areaAry: [],
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        value: null,
        dateRange: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/news/manager";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begDateMin = query.dateRange[0];
        query.begDateMax = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "createAt,desc",
          title: this.query.value,
        },
        query
      );
      return true;
    },

    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.resetForm();
      _this.dialog = true;
    },
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.resetForm(JSON.parse(JSON.stringify(data)));
      _this.dialog = true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    publish(id) {
      this.$confirm("此操作将进行通告发布, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          publi(id).then((res) => {
            this.init();
            this.$message({
              type: "success",
              message: "发布成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发布",
          });
        });
    },
  },
};
</script>